/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  width: 356px;
  color: #444444;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  font-weight: 400;
  font-size: 13px;
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08),
    0px 1px 4px rgba(17, 43, 66, 0.1);
  padding: 14px 14px 21px 18px;
  border-radius: 10px;
  color: #737575;
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
  background: white;
}
.Toastify__toast--success {
  background: white;
}
.Toastify__toast--warning {
  background: #ffffff;
}
.Toastify__toast--error {
  background: white;
}
.Toastify__toast-body {
  padding: 0;
  font-family: 'Inter';
  font-size: 14px;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__toast--error > .Toastify__close-button {
  color: #737575;
}
.Toastify__toast--success > .Toastify__close-button {
  color: #737575;
}
.Toastify__toast--warning > .Toastify__close-button {
  color: #737575;
}
.Toastify__toast--info > .Toastify__close-button {
  color: #737575;
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
}

.Toastify__toast--error > .Toastify__progress-bar {
  background-color: #e24c4c;
}

.Toastify__toast--success > .Toastify__progress-bar {
  background-color: #5b9e28;
}

.Toastify__toast--warning > .Toastify__progress-bar {
  background-color: #f1c40f;
}

.Toastify__toast--info > .Toastify__progress-bar {
  background-color: #3dabf5;
}

.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
}

@media (max-width: 480px) {
  .Toastify__toast-container {
    width: 100%;
  }

  .Toastify__toast {
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 8px;
  }
}

@media (min-width: 960px) {
  .Toastify__toast-container--bottom-center {
    left: calc(50% + 385px);
    bottom: 1.4rem;
  }
}
