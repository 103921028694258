@media (max-width: 320px) {
  .rec.rec-arrow {
    width: 40px;
    height: 40px;
    min-width: 40px;
    line-height: 40px;
  }
}

/* square buttons */
.rec.rec-arrow {
  background-color: white;
  font-size: 1.5em;
  color: #999999;
  width: 40px;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
}

/* round buttons on hover */
.rec.rec-arrow:hover {
  background-color: #3dabf5 !important;
  color: white;
  box-shadow: 0px 0px 7px rgba(119, 119, 119, 0.15);
}

.rec.rec-arrow:hover:enabled,
.rec.rec-arrow:focus:enabled {
  color: white;
  background-color: #3dabf5;
  box-shadow: 0px 0px 7px rgba(119, 119, 119, 0.15);
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}
